#text{
    display: flex;
    justify-content: center;
    padding: 20px 20px 0;
    color: var(--darkergrey);
}

#product-info{
    margin: 0 5px 10px;
    max-width: 800px;
}
#product-intro{
    display: flex;
    flex-direction: column;
    margin: 0 5px;
}

#product-intro>div{
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px;
}
#product-intro>div>*{
    margin: 0 5px 8px 0;
}

#product-info, #product-intro>div{
    border-radius: 4px;
    box-shadow: 0 0 0.4em var(--grey);
    padding: 15px;
}
#product-intro>div>h2, #product-info>h2{
    width: 100%;
    margin: 0 0 5px 0px;
    font-size: 22px;
}
#product-info>p, #product-intro>div>p{
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
    margin-top: 10px;
}
p.recap-p>em{
    font-size: 14px;
}
li.standart-list{
    list-style:circle;
    margin: 7px 0 0 30px;
    font-size: 13px;
}

#table{
    margin: 0 auto;
    padding: 0 25px;
    max-width: 1160px
}

@media screen and (max-width: 900px){
    #text{
        flex-direction: column;
        align-items: center;
    }
    #product-info{
        max-width: 610px;
    }
    #product-intro{
        flex-direction: row;
    }
    #product-intro>div{
        margin: 0 5px 10px;
    }
    #table{
        margin: 0 auto;
        width: 610px;
        padding: 0;
    }
}
@media screen and (max-width: 700px){
    #product-info{
        width: 90%;
        max-width: 415px;
    }
    #product-intro{
        flex-direction: column;
        align-items: center;
        margin: 0;
    }
    #product-intro>div{
        width: 90%;
        max-width: 450px;
    }
    #table{
        margin: 0 auto;
        max-width: 415px;
        padding: 0;
        width: 82%;
    }
}

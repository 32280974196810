/* Conteúdo */
section.subject{
    padding: 20px 40px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.content-div, section.subject>img{
    position: relative;
    margin: 0 5px;
}

.content-div>h2{
    font-size: 45px;
    width: 349px;
    display: flex;
    flex-direction: column;
    color: var(--darkergrey);
    margin-bottom: 5px;
}
.content-div>p{
    margin-bottom: 20px;
    font-size: 18px;
    width: 340px;
    color: var(--darkgrey);
}

#intro>img{
    width: 350px;
    height: 280px;
}
#sobre-nos img{
    width: 350px;
    height: 210px;
}
#video{
    position: absolute;
    top: 8.2%;
    left: 50%;
    transform: translate(-50%);
    width: 73.2%;
    height: 76.6%;
}

form{
    display: flex;
    flex-wrap: wrap;
    width: 350px;
    padding: 0 30px;
    align-items: center;
    height: 310px;
    justify-content: space-evenly;
}
form>button{
    width: 100%;
}
#loading-el{
    position: absolute;
}

#seguradoras>img{
    height: 129px;
    width: 350px;
}

@media screen and (max-width: 798px){
    .content-div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content-div>h2, .content-div>p{
        text-align: center;
    }
    #sobre-nos, #seguradoras{
        flex-direction: column-reverse;
    }
}
@media screen and (max-width: 650px){
    section.subject{
        padding: 20px 0;
    }
}
@media screen and (max-width: 500px){
    .content-div>h2{
        font-size: 30px;
    }
    .content-div>p{
        font-size: 16px;
        width: 300px;
    }
}

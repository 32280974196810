header{
    height: 290px;
    background-image: url('../imagens/banner1-expanded-sky-darker-1366x737.webp');
    background-size: cover;
    background-position: top;
    position: relative;
    box-shadow: 0 0 0.4em var(--darkgrey);
}
header>h1, header>p, header>.button{
    position: absolute;
    left: 50%;
    bottom: 26%;
    transform: translate(-50%);
    color: var(--white);
}
header>h1{
    font-size: 100px;
}
header>p{
    font-size: 19px;
    white-space: nowrap;
}
header>.button{
    bottom: 7%;
}
nav{
    display: flex;
    align-items: center;
    justify-content: end;
    color: var(--white);
    padding: 15px 20px;
    font-size: 13px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2;
    transition: 0.15s;
}
nav.scrolled{
    color: var(--black);
    background-color: var(--white);
    box-shadow: 0 0 0.4em var(--grey);
}
.nav-divider{
    width: 1px;
    height: 20px;
    margin: 0 20px;
    background-color: var(--white);
}
.nav-divider:last-of-type{
    margin: 0 0 0 20px;
    background-color: var(--transparent);
}
.nav-divider-scrolled{
    background-color: var(--grey);
}

@media screen and (max-width: 850px){
    header>h1{
        font-size: 80px;
        bottom: 28%;
    }
    header>p{
        font-size: 17px;
        bottom: 27%;
    }
}
@media screen and (max-width: 600px){
    section#main>h2{
        font-size: 50px;
    }
    nav{
        font-size: 10px;
        padding: 12px 0;
        justify-content: center;
    }
    .nav-divider{
        margin: 0 7px;
    }
    .nav-divider:last-of-type{
        margin: 0 0 0 10px;
    }
    header{
        background-image: url('../imagens/banner1-expanded-sky-darker-683x368.webp');
    }
}
@media screen and (max-width: 500px){
    header>h1{
        font-size: 55px;
        bottom: 37%;
    }
    header>p{
        font-size: 15px;
        bottom: 34%;
    }
    header>.button{
        bottom: 14%;
    }
}

footer{
    background-image: linear-gradient(to bottom, var(--secondary), var(--secondary-light));
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    color: var(--secondary-lightest);
    padding: 20px 15px;
    margin-top: 20px;
    box-shadow: 0 0 0.4em var(--darkgrey);
}
.footer-div{
    margin: 15px;
}

footer .button{
    background-image: linear-gradient(to left, var(--secondary), var(--secondary-light));
}
.footer-div>p{
    color: var(--secondary-lighter);
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
}
.footer-div>p.link{
    text-decoration: underline;
}

#footer-header{
    text-align: center;
    padding: 0;
}
#footer-header>p{
    font-size: 12px;
    color: var(--secondary-lightest);
    margin: -8px 0 25px;
}

div#maps{
    background-color: var(--secondary-light);
}
div#maps>iframe{
    display: block;
    width: 100%;
    height: 280px;
    border: none;
    opacity: 0.6;
    padding: 0 8px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}
p#copyright{
    font-size: 10px;
    padding: 2px;
    background-color: var(--secondary);
    text-align: center;
    color: var(--secondary-lighter)
}

@media screen and (max-width: 949px){
    footer{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
